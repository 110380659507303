// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-market-research-tsx": () => import("./../../../src/pages/market-research.tsx" /* webpackChunkName: "component---src-pages-market-research-tsx" */),
  "component---src-pages-our-data-tsx": () => import("./../../../src/pages/our-data.tsx" /* webpackChunkName: "component---src-pages-our-data-tsx" */),
  "component---src-pages-our-platform-tsx": () => import("./../../../src/pages/our-platform.tsx" /* webpackChunkName: "component---src-pages-our-platform-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-templates-market-research-tsx": () => import("./../../../src/templates/market-research.tsx" /* webpackChunkName: "component---src-templates-market-research-tsx" */),
  "component---src-templates-reports-tsx": () => import("./../../../src/templates/reports.tsx" /* webpackChunkName: "component---src-templates-reports-tsx" */)
}

